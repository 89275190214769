<template>
  <div class="activityDetail">
    <div class="activityDetail-body">
      <!-- 导航图片 -->
      <div
        class="introImg"
        @click="bannerClick(activityDetail.activityPicture)"
      >
        <img :src="activityDetail.activityPicture" alt="" />
      </div>
      <!-- 标题 -->
      <div class="titleIntro">
        <div class="h3">{{ activityDetail.activityName }}</div>
      </div>
      <!-- 内容 -->
      <div class="activityDetail-wrapper">
        <!-- 活动规则 -->
        <div class="activityDetail-t">
          <div class="title">活动规则</div>
          <div class="time">
            <div class="item">
              <div class="item-l">报名开始时间</div>
              <div class="item-r">
                {{ activityDetail.applyStartDate }}
              </div>
            </div>
            <div class="item">
              <div class="item-l">活动开始时间</div>
              <div class="item-r">{{ activityDetail.startDate }}</div>
            </div>
          </div>
          <div class="peopleNum">
            <div class="item">
              <div class="item-l">活动人数</div>
              <div class="item-r">
                <span>{{ activityDetail.applyUserCount }}</span
                >/{{ activityDetail.limitCount }}
              </div>
            </div>
          </div>
          <div class="address">
            <div class="item">
              <div class="item-l">活动地点</div>
              <div class="item-r">
                {{ activityDetail.activityAddress }}
              </div>
            </div>
          </div>
        </div>
        <!-- 活动回顾 -->
        <div class="activityDetail-c" v-if="activityDetail.hasFeedback == 1">
          <div class="title">活动回顾</div>
          <div class="body">
            <v-h5Mtml :content="activityDetail.feedback"></v-h5Mtml>
          </div>
          <div class="fbVideoUrl" v-if="activityDetail.fbVideoUrl">
            <video :src="activityDetail.fbVideoUrl"></video>
          </div>
          <div class="fbImageUrls" v-if="activityDetail.fbImageUrls">
            <img
              v-for="(item, index) in activityDetail.fbImageUrls
                ? activityDetail.fbImageUrls.split(',')
                : []"
              :key="index"
              :src="item"
              @click="bannerClick(activityDetail.fbImageUrls.split(','), index)"
            />
          </div>
        </div>
        <!-- 活动内容 -->
        <div class="activityDetail-c" v-else>
          <div class="title">活动内容</div>
          <div class="body">
            <v-h5Mtml :content="activityDetail.content"></v-h5Mtml>
          </div>
          <div class="fbVideoUrl" v-if="activityDetail.fbVideoUrl">
            <video :src="activityDetail.videoUrl"></video>
          </div>
          <div
            class="fbImageUrls"
            v-if="
              activityDetail.imageUrls && activityDetail.imageUrls != undefined
            "
          >
            <img
              v-for="(item, index) in activityDetail.imageUrls
                ? activityDetail.imageUrls.split(',')
                : []"
              :key="index"
              :src="item"
              mode="widthFix"
              @click="bannerClick(activityDetail.imageUrls.split(','), index)"
            />
          </div>
        </div>
      </div>
      <!-- 评论详情 -->
      <div class="commentList">
        <v-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="comments">
            <div class="head-body">
              <div class="header">
                <span>{{ titleText }}</span
                ><span>（{{ commentsData.commentCount || "0" }}）</span>
              </div>
            </div>

            <div class="comments-empty" v-if="!commentsData.commentCount">
              暂无评论
            </div>
            <v-list
              :finished="finished"
              @onLoad="onLoad"
              ref="list"
              :finishedText="finishedText"
            >
              <div class="main">
                <div
                  class="comments-main"
                  v-for="(comment, index) in commentList"
                  :key="index"
                >
                  <div class="userPhoto">
                    <img
                      :src="
                        comment.avatar
                          ? comment.avatar
                          : require('./img/default_avatar.png')
                      "
                      alt=""
                    />
                  </div>
                  <div class="userInfo">
                    <div class="info-title">{{ comment.userName }}</div>
                    <div class="info-content">
                      {{ comment.commentContent }}
                    </div>
                    <div class="info-pic" v-if="comment.commentPic[0] != ''">
                      <div
                        v-for="(item, index) in comment.commentPic2"
                        :key="index"
                        @click="bannerClick(comment.commentPic, index)"
                      >
                        <img :src="item" alt="" />
                      </div>
                    </div>
                    <div class="info-reply" v-if="isEmpty(comment.latestReply)">
                      <div class="info-reply__content">
                        <span class="content-user">{{
                          comment.latestReply.fromUserName
                        }}</span>
                        <span
                          class="content-to-user"
                          v-if="comment.latestReply.toUserName"
                        >
                          回复 @{{ comment.latestReply.toUserName }}</span
                        >
                        <span class="content-colon">:</span>
                        <span class="content-text">
                          {{ comment.latestReply.replyContent }}
                        </span>
                      </div>
                      <div
                        class="info-reply__btn"
                        v-if="comment.replyCount > 1"
                        @click="toRouteReply(comment)"
                      >
                        <span>共 {{ comment.replyCount }} 条回复</span>
                        <img :src="require('./img/icon-arrow.png')" alt="" />
                        <!-- <i class="el-icon-arrow-right"></i> -->
                      </div>
                    </div>
                    <div class="info-foot">
                      <div class="foot-time">{{ comment.createTime }}</div>
                      <div class="foot-wrap">
                        <div class="iconText" @click="toComments(comment)">
                          <div class="icon">
                            <img :src="require('./img/icon-msg.png')" />
                          </div>
                        </div>
                        <div class="iconText" @click="toClickLike(comment)">
                          <div class="icon icon-up">
                            <img
                              v-if="!comment.hasPraised"
                              :src="require('./img/icon-like.png')"
                            />
                            <img
                              v-else
                              :src="require('./img/icon-like-red.png')"
                              alt=""
                            />
                          </div>
                          <div class="text">
                            <span :class="{ hasPraised: comment.hasPraised }">{{
                              comment.praiseCount
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-list>
          </div>
        </v-refresh>
      </div>
    </div>
    <v-comments
      ref="vComments"
      v-model="text"
      isAutoClose
      showUploadImgBtn
      :show.sync="isShowComments"
      @acceptSend="acceptSend"
    ></v-comments>
    <!-- 固定导航栏 -->
    <div class="operate isScreen">
      <div class="operate-body">
        <div class="operate-l" @click="toComments(null)">
          <div class="txt">去评论</div>
        </div>
        <div class="operate-r">
          <div v-if="activityDetail.activityStatus == 2" class="operate-rl">
            <div
              v-if="
                activityDetail.limitCount > activityDetail.applyUserCount ||
                activityDetail.limitCount == 0
              "
              class="item"
            >
              <div
                class="oItem"
                v-if="
                  activityDetail.isApplied != 1 ||
                  (activityDetail.isApplied == 1 &&
                    activityDetail.allowApplyMulti == 1)
                "
                @click="toBeginApply"
              >
                <div class="txt">活动报名</div>
              </div>
              <div class="oItem" v-else>
                <div class="txt">已报名</div>
              </div>
            </div>
            <div v-else class="item activityDetail-gray">
              <div class="txt">报名人数已满</div>
            </div>
          </div>
          <div v-else class="operate-rl">
            <div class="item activityDetail-gray">
              <div class="txt">暂不可报名</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 报名弹窗 -->
    <div class="dialog" v-if="isDialog" @click="close">
      <div class="dialog-box">
        <div class="dialog-title">
          <div class="title-text">活动场次</div>
        </div>
        <div class="dialog-body">
          <div
            v-for="(item, index) in activityDetail.scheduleList"
            :class="['item', showClass(item)]"
            :key="index"
            @click.stop="toClick(item, index)"
          >
            <div class="item-t">
              <div class="oItem">
                <div class="oItem-l">报名时间:</div>
                <div class="oItem-r">
                  {{ item.applyStartDate }}-{{ item.applyEndDate }}
                </div>
              </div>
              <div class="oItem">
                <div class="oItem-l">活动时间:</div>
                <div class="oItem-r">
                  {{ item.startDate }}-{{ item.endDate }}
                </div>
              </div>
              <div class="oItem">
                <div class="oItem-l">参与人数:</div>
                <div class="oItem-r">
                  <text class="applyUserCount">{{ item.applyUserCount }}</text
                  >/<text>{{ item.limitCount }}</text>
                </div>
              </div>
              <div class="oItem">
                <div class="oItem-l">单次限报:</div>
                <div class="oItem-r">
                  {{
                    item.perLimitCount && item.perLimitCount != 0
                      ? item.perLimitCount + "人"
                      : "不限制"
                  }}
                </div>
              </div>
            </div>
            <div class="item-b">
              <div class="item-bl" v-if="item.activityStatus == 2">
                <div
                  class="item-bl-c"
                  v-if="
                    item.limitCount > item.applyUserCount ||
                    item.limitCount == 0
                  "
                >
                  <div v-if="item.isApplied == 1">已报名</div>
                  <div v-else>可选择</div>
                </div>
                <div v-else class="item-bl-c">该场次报名人数已满</div>
              </div>
              <div class="item-bl" v-else>该场次不可报名</div>
              <div class="item-br">
                <img v-if="item.isSelect" :src="require('./img/select.png')" />
                <img v-else :src="require('./img/noSelect.png')" />
              </div>
            </div>
          </div>
          <div class="sure" @click="sure">确定</div>
        </div>
      </div>
    </div>
    <!-- 志愿者弹框 -->
    <div class="blackMask" v-if="showDialog" @click="showDialog = false">
      <div class="box">
        <div class="dialog">
          <div class="text">你还未申请志愿者</div>
          <div class="btn" @click="apply">立即申请</div>
        </div>
      </div>
    </div>
    <v-picList
      :startPosition="startPosition"
      v-model="showImg"
      :imgList="picList"
    >
    </v-picList>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import {
  getActivityDetailUrl,
  judgeVolunteerUrl,
  addCommentURL,
  getTopicCommentList,
  cancelPraiseURL,
  addPraiseURL,
} from "./api.js";
import {
  toRegister,
  toRegisterWT,
  isWxMiniPro,
  gloabalCount,
} from "@/utils/common";
import { regImg } from "@/utils/utils";
import { mapState } from "vuex";
import { vComments } from "@/components/control/index";
export default {
  name: "activitySquareDetail",
  components: {
    vComments,
  },
  data() {
    return {
      baseList: {},
      activityDetail: {},
      errorMsg: "",
      showImg: false,
      startPosition: 0,
      picList: [],
      scheduleId: "",
      isDialog: false, //报名弹窗
      showDialog: false, //志愿者弹窗
      isShowComments: false, //发表评论
      text: "", //评论内容
      curReply: null, // 用于记录回复的哪一条评论
      commentPic: "",
      toRegisterParams: {
        url: "/xubPages/activityDetail/index",
        oParams: {},
        name: "热门活动报名",
      },
      titleText: "评论",
      curPage: 1,
      pageSize: 10,
      finished: false,
      finishedText: "",
      commentList: [],
      commentsData: {},
      isLoading: false,
      isStop: 1,
      activityId: "",
    };
  },
  created() {
    this.activityId = this.$route.query.activityId;
    gloabalCount(this.activityId, 3, 1);
    this.getActivityDetail();
  },

  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },

  mounted() {
    // 埋点
  },
  destroyed() {
    if (isWxMiniPro()) {
      wx.miniProgram.postMessage({
        data: JSON.stringify({ activityId: "", title: "", img: "" }),
      });
    }
    clearTimeout(this.timer);
  },
  methods: {
    // 点击预览图片
    bannerClick(list, index) {
      this.showImg = true;
      if (index != undefined) {
        this.picList = list;
        this.startPosition = index;
      } else {
        this.picList.unshift(this.activityDetail.activityPicture);
        this.startPosition = 0;
      }
    },
    //活动报名
    async toBeginApply() {
      if (this.activityDetail.activityCategory == 20) {
        let res = await this.getJudgeVolunteerUrl();
        if (res.code == 200) {
          if (res.data.isVolunteer == 0) {
            this.showDialog = true;
            return;
          } else {
            if (res.data.isCommitmentLetter == 0) {
              this.$router.push({
                name: "undertakingApply",
                query: {
                  activityId: this.activityId,
                  isFromMini: 1,
                },
              });
              return;
            }
          }
        } else {
          return;
        }
      }
      if (
        this.activityDetail.scheduleList &&
        this.activityDetail.scheduleList.length != 0 &&
        this.errorMsg == ""
      ) {
        this.isDialog = true;
      } else if (this.errorMsg == "") {
        this.toApply();
      }
    },
    //获取报名信息
    async getJudgeVolunteerUrl() {
      let res = await this.$axios({
        path: judgeVolunteerUrl,
        method: "post",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        data: {
          userId: this.userId,
          activityId: this.activityId,
        },
      });
      return res;
    },
    // 点击评论图标跳出评论控件
    async toComments(item) {
      this.commentPic = [];
      this.curReply = item;
      console.log("this.curReply=====>", this.curReply);
      this.isShowComments = true;
      // let oParams = {
      //   activityId: this.activityId,
      // };
      // if (
      //   await toRegister(this.$route.path, this.$route.query, "热门活动报名")
      // ) {
      //   this.text = "";
      //   this.commentPic = [];
      //   this.curReply = item;
      //   this.isShowComments = true;
      // }
    },
    // 接受评论组件发送过来的信息
    acceptSend(val, picList) {
      if (!(val.trim && val.trim())) {
        this.$toast({ message: "请输入内容", duration: 1500 });
        return;
      }
      this.commentPic = picList;
      const item = this.curReply;
      let params = {};
      if (item) {
        // 如果存在则为回复的回复
        params = {
          topicId: item.topicId, // 话题id
          commentId: item.commentId, // 评论id
          replyId: item.replyId,
          // parentId: "", // 上级回复id 预留字段，评论类型为2的场合才可能有值，将来如果要实现回复树状展示时可以利用
          commentType: 1, // 评论类型 0：针对话题的评论；1：针对评论的回复；2：针对回复的回复
          commentContent: this.text, // 评论内容
          fromUser: this.userId, // 评论人ID
          toUser: item.userId, // 被回复人ID
        };
      } else {
        // 反之则为评论的回复
        params = {
          topicId: this.activityDetail.topicId, // 话题id
          commentId: null, // 评论id
          // parentId: "", // 上级回复id 预留字段，评论类型为2的场合才可能有值，将来如果要实现回复树状展示时可以利用
          commentType: 0, // 评论类型 0：针对话题的评论；1：针对评论的回复；2：针对回复的回复
          commentContent: this.text, // 评论内容
          fromUser: this.userId, // 评论人ID
          toUser: null, // 被回复人ID
          commentPic: this.commentPic, // 需要上传图片的地址
        };
        console.log("this.userId=====>", this.userId);
      }
      // 1. 调接口
      this.$toast.loading({
        duration: 1500, // 持续展示 toast
        forbidClick: true,
        message: "发送中...",
      });
      this.$axios
        .post(addCommentURL, {
          ...params,
          tenantId: this.tenantId || undefined,
          communityId: this.communityId || undefined,
          roomId: this.houseId || undefined,
        })
        .then((res) => {
          if (res.code === 200) {
            // 2. 关闭弹框
            this.isShowComments = false;
            // 3. 关闭后清楚数据
            this.text = "";
            // 4. 调评论接口
            this.onRefresh();
            // item.commentCount += 1;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    //跳转到报名提交页面
    async toApply() {
      this.$router.push({
        name: "confirmSubmit",
        query: {
          activityId: this.activityId,
          addSelf: 1,
          scheduleId: this.scheduleId,
          activityCategory: this.activityDetail.activityCategory,
        },
      });
    },
    //报名确定
    sure() {
      let arr = [];
      this.activityDetail.scheduleList.forEach((ele, ind) => {
        arr.push(ele.isSelect);
      });
      if (arr.indexOf(true) == -1) {
        this.$toast({ message: "请选择场次", duration: 500 });
        return;
      }
      this.activityDetail.scheduleList.forEach((ele, ind) => {
        if (ele.isSelect) {
          this.scheduleId = ele.id;
        }
      });
      this.toApply();
    },
    //获取详情列表
    getActivityDetail() {
      let params = {
        activityId: this.activityId,
        userId: this.userId,
      };
      this.$axios
        .get(`${getActivityDetailUrl}`, { params })
        .then(async (res) => {
          if (res.code === 200) {
            this.activityDetail = res.data;
            await this.onLoad(this.activityDetail.topicId);
            this.activityDetail.scheduleList &&
              this.activityDetail.scheduleList.forEach((ele) => {
                ele.isSelect = false;
              });
            this.activityDetail.content = regImg(this.activityDetail.content);
            this.activityDetail.feedback = regImg(this.activityDetail.feedback);
            if (this.activityDetail.activityPicture) {
              this.activityDetail.activityPicture =
                this.activityDetail.activityPicture.ossResize(375, 211);
            }
            if (isWxMiniPro()) {
              wx.miniProgram.postMessage({
                data: JSON.stringify({
                  activityId: this.activityId,
                  title: this.activityDetail.activityName,
                  img: this.activityDetail.activityPicture,
                }),
              });
            }
          } else {
          }
        });
    },
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    //获取评论信息
    async onLoad(val) {
      if (this.activityDetail.topicId) {
        this.$axios
          .get(getTopicCommentList, {
            params: {
              topicId: this.activityDetail.topicId,
              userId: this.userId,
              curPage: this.curPage,
              pageSize: this.pageSize,
            },
          })
          .then((res) => {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.commentList = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              this.commentsData = res.data;
              if (this.curPage === 1) {
                this.commentList = res.data.records;
              } else {
                const list = this.commentList.map((v) => v.commentId);
                this.commentList.forEach((ele) => {
                  if (ele.avatar) {
                    ele.avatar = ele.avatar.ossResize(72, 72);
                  }
                });
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.commentId) || this.commentList.push(item);
                });
              }
              this.commentList.forEach((item) => {
                item.commentPic = item.commentPic.split(",");
                item.commentPic2 = item.commentPic;
                let list = [];
                item.commentPic2.forEach((item1) => {
                  list.push(this.$handleImg(180, 180, item1));
                });
                item.commentPic2 = list;
              });
              this.curPage++;
              console.log("this.curpage=====>", this.curPage);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    // 点赞和取消点赞
    toClickLike(data) {
      if (this.isStop == 1) {
        this.isStop = 0;
        // item 存在则是评论点赞，反之则是话题点赞
        const item = data;
        this.$axios
          .post(item.hasPraised ? cancelPraiseURL : addPraiseURL, null, {
            params: {
              targetId: data ? item.commentId : item.id, // commentId 评论主键id
              userId: this.userId,
              tenantId: this.tenantId || undefined,
              communityId: this.communityId || undefined,
              roomId: this.houseId || undefined,
            },
          })
          .then((res) => {
            this.isStop = 1;
            if (res.code === 200) {
              console.log(res, "----");
              if (item.hasPraised) {
                // 取消点赞
                item.hasPraised = false;
                item.praiseCount -= 1;
              } else {
                // 点赞
                item.hasPraised = true;
                item.praiseCount += 1;
              }
              return;
            }
            this.$toast(res.msg || "操作失败，请稍后重试！");
          })
          .catch((res) => {
            this.$toast(res.msg || "操作失败，请稍后重试！");
          })
          .finally(() => {
            // this.isLoading = false;
          });
      }
    },
    // 跳转回复页面
    toRouteReply(item) {
      this.$router.push({
        name: "replyDetail",
        query: {
          title: `${item.replyCount}条回复`,
          detail: JSON.stringify(item),
          latestReply: null, // 用于覆盖掉回复数据
        },
      });
    },
    //点击报名
    toClick(item, index) {
      if (this.showClass(item) == "inactive") {
        return;
      }
      this.activityDetail.scheduleList.forEach((ele, ind) => {
        if (index != ind) {
          ele.isSelect = false;
        }
      });
      item.isSelect = !item.isSelect;
      this.$forceUpdate();
    },
    //立即申请
    apply() {
      this.$router.push({
        name: "volunteerApply",
        query: {
          userId: this.userId,
          isFromMini: 1,
        },
      });
    },
    // 添加动态类名
    showClass(item) {
      if (item.activityStatus == 2) {
        if (item.limitCount > item.applyUserCount) {
          if (item.isApplied != 1) {
            return "active";
          } else {
            return "inactive";
          }
        } else {
          return "inactive";
        }
      } else {
        return "inactive";
      }
    },
    // 判断是否是 空对象或者null
    isEmpty(val) {
      if (!val) {
        return false;
      } else {
        return Object.keys(val).length > 0;
      }
    },
    // 报名弹窗关闭
    close() {
      this.isDialog = false;
    },
  },
};
</script>
<style lang="less" scoped>
.activityDetail {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .blackMask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.25);
    z-index: 10000;
    padding: 0 65px;
    .box {
      width: 100%;
      height: 318px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      .dialog {
        padding-top: 56px;
        box-sizing: border-box;
        background: #ffffff;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        .btn {
          width: 440px;
          height: 66px;
          background: linear-gradient(180deg, #73a8ff 0%, #307cff 100%);
          border-radius: 10px;
          line-height: 66px;
          text-align: center;
          font-size: 30px;
          font-weight: 600;
          color: #ffffff;
          margin: 0 auto;
        }
        .text {
          text-align: center;
          font-size: 34px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          padding-bottom: 92px;
          box-sizing: border-box;
        }
      }
    }
  }
  .dialog {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    .dialog-box {
      position: relative;

      box-sizing: border-box;
      width: 90%;
      height: 90%;
      padding: 68px 0 120px;
      margin: 10% auto;
      overflow-y: auto;
      background: #fff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      .dialog-title {
        height: 44px;

        position: relative;
        font-size: 32px;
        font-weight: bold;
        color: #000000d9;
        line-height: 44px;
        .title-text {
          position: absolute;
          left: 56px;
          top: 50%;
          transform: translateY(-50%);
        }
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 32px;

          width: 174px;
          height: 16px;
          background: #e2e2e2;
          border-radius: 7px;
        }
      }
      .dialog-body {
        padding: 0 32px;
        height: calc(100% - 44px);
        overflow-y: auto;
        overflow-x: hidden;
        .item {
          padding: 40px 0;
          border-bottom: 2px solid #eeeeeeff;
          .item-t {
            .oItem {
              display: flex;
              font-size: 24px;
              align-items: center;
              margin: 10px 0;
              .oItem-l {
                width: 122px;
              }
              .oItem-r {
                flex: 1;
                .applyUserCount {
                  color: #fe6f16;
                }
              }
            }
          }
          .item-b {
            display: flex;
            font-size: 24px;
            justify-content: space-between;
            .item-br {
              width: 40px;
              height: 40px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .inactive {
          color: #999;
        }
      }
      .sure {
        position: absolute;
        bottom: 32px;
        left: 0;
        right: 0;
        margin: auto;
        width: 550px;
        height: 66px;
        font-size: 28px;
        line-height: 66px;
        text-align: center;
        font-weight: bold;
        color: #ffffff;
        background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
        border-radius: 10px;
      }
    }
  }
  .activityDetail-body {
    padding-bottom: 250px;
  }
  .title {
    position: relative;
    padding-left: 24px;
    font-size: 32px;
    font-weight: 600;
    color: #323334;
    margin-bottom: 32px;
    line-height: 44px;
    &::before {
      content: "";
      position: absolute;
      // top: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      // margin: auto;
      width: 174px;
      height: 16px;
      // background: #fe6f16;
      // border-radius: 4px;
      background: #e2e2e2;
      border-radius: 7px;
    }
  }
  .introImg {
    padding: 10px 32px 0;
    height: calc(100vw / 760 * 334);
    img {
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }
  .titleIntro {
    padding: 32px 32px 0 32px;
    .h3 {
      font-size: 36px;
      font-weight: 600;
      color: #323334;
      line-height: 52px;
    }
  }
  .activityDetail-wrapper {
    padding: 38px 32px 46px;
    margin: 50px 32px;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 16px;
  }
  .activityDetail-t {
    // margin-top: 80px;
    // padding: 0 32px;
  }
  .activityDetail-c {
    margin-top: 80px;
    // padding: 20px 40px;
    .body {
      word-wrap: break-word;
      font-size: 30px !important;
      span {
        font-size: 30px !important;
      }

      img {
        width: 100% !important;
      }
    }
    .fbVideoUrl {
      width: 100%;
      height: 400px;
      video {
        width: 100%;
        height: 100%;
      }
    }
    .fbImageUrls {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .commentList {
    .comments {
      padding: 0 0 32px 0;

      .head-body {
        padding: 0 32px 0;
        box-shadow: 0px -2px 0px 0px #f0f0f0;
        border-bottom: 2px solid rgba(240, 240, 240, 1);
      }

      .header {
        padding: 28px 0px;
        font-size: 28px;
        font-weight: 700;
        color: #1a1c34;
        line-height: 44px;

        > text {
          &:nth-of-type(1) {
            margin-right: 8px;
          }

          // &:nth-of-type(2) {
          // }
        }
      }

      .comments-empty {
        display: flex;
        font-size: 28px;
        line-height: 44px;
        color: #999999;
        justify-content: center;
      }

      .main {
        padding: 0 32px;
      }

      .comments-main {
        display: flex;
        margin-top: 32px;

        .userPhoto {
          height: 72px;
          width: 72px;
          min-width: 72px;
          overflow: hidden;
          border-radius: 50%;

          > img {
            width: 100%;
            height: 100%;
          }
        }

        .userInfo {
          word-wrap: break-word;
          flex: 1;
          margin-left: 16px;
          max-width: calc(100% - 88px);

          .info-title {
            box-sizing: border-box;
            width: 100%;
            font-size: 28px;
            font-weight: 700;
            color: #333333;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .info-content {
            font-size: 32px;
            margin-top: 8px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 44px;
          }

          .info-pic {
            padding: 32px 0;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            img {
              width: 180px;
              height: 180px;
              margin-bottom: 15px;
              margin-right: 15px;
            }
          }

          .info-reply {
            background: #f7f7f7;
            line-height: 40px;
            padding: 24px;
            margin-top: 24px;
            word-wrap: break-word;
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;

            .info-reply__content {
              display: flex;
              align-items: center;

              .content-user,
              .content-colon {
                font-weight: 700;
                color: #333333;
              }

              .content-user {
                white-space: nowrap;
              }

              .content-to-user {
                color: blur;
                font-weight: 700;
              }

              .content-text {
                font-weight: 400;
                color: #666666;
              }
            }

            .info-reply__btn {
              margin-top: 8px;
              white-space: nowrap;
              font-weight: 700;
              color: #333333;
              display: flex;
              align-items: center;

              > text {
                font-weight: 700;
                color: #333333;
              }

              img {
                width: 24px;
                height: 24px;
              }
            }
          }

          .info-foot {
            margin-top: 24px;
            line-height: 32px;
            display: flex;

            .foot-time {
              color: #a8a8a8;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #a8a8a8;
            }

            .foot-wrap {
              flex: 1;
              display: flex;
              justify-content: flex-end;

              > .iconText {
                display: flex;
                // margin-right: 32px;
                color: #a8a8a8;
                align-items: center;

                &:not(:last-child) {
                  margin-right: 32px;
                }

                .icon {
                  width: 32px;
                  height: 32px;
                  overflow: hidden;

                  &.icon-up {
                    > img {
                      margin-top: -2px;
                    }
                  }

                  > img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .text {
                  margin-left: 4px;
                  display: flex;
                  align-items: center;
                  height: 32px;

                  text {
                    &.hasPraised {
                      color: #ff853a;
                    }

                    // color: #ff853a;
                    font-size: 24px;
                    line-height: 1;
                  }
                }
              }
            }
          }
        }
      }

      .no-more_comments {
        display: flex;
        margin-top: 48px;
        font-size: 28px;
        line-height: 44px;
        color: #999999;
        justify-content: center;
      }
    }
  }

  .actiName,
  .time,
  .address,
  .peopleNum {
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }
    .item-l {
      width: 168px;
      margin-right: 48px;
      font-size: 28px;
      font-weight: 400;
      color: #323334;
      line-height: 40px;
    }
    .item-r {
      flex: 1;
      font-size: 28px;
      font-weight: 400;
      color: #97999b;
      line-height: 40px;
      span {
        color: #fe6f16;
      }
    }
  }
  .address {
    .item {
      align-items: flex-start;
    }
  }
  .activityDetail-b {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #009aff;
    height: 96px;
    position: fixed;
    bottom: 0;
    left: 0;
    color: #fff;
    .btn-l {
      width: 60px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .btn-r {
      color: #fff;
      font-size: 32px;
      line-height: 96px;
    }
  }
  .activityDetail-gray {
    background: #cccccc;
  }

  .operate {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);

    z-index: 99;
    .operate-body {
      border-radius: 10px;
      padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
      padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */

      .operate-l {
        box-sizing: border-box;
        width: 550px;
        height: 66px;
        font-size: 28px;
        line-height: 62px;
        text-align: center;
        font-weight: bold;
        color: #54a2ac;
        border-radius: 10px;
        background: #fff;
        border: 2px solid #54a2ac;
        overflow: hidden;
      }
      .operate-r {
        margin-top: 28px;

        width: 550px;
        height: 66px;
        .operate-rl {
          width: 100%;
          height: 100%;
          font-size: 28px;
          line-height: 66px;
          text-align: center;
          font-weight: bold;
          border-radius: 10px;
          overflow: hidden;
        }
        .item,
        .oItem {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          color: #ffffff;
          background: linear-gradient(180deg, #b0e2dd 0%, #54a2ac 100%);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .activityDetail-gray {
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
          color: #a7a7a7ff;
        }
      }
    }
  }
}
.isScreen {
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}
</style>
